import { Injectable, effect, inject, signal } from '@angular/core';

import { UserPermission } from '@sulser-print/models/permission/user-permission';
import { getEnvironmentConfig } from '@sulser-print/tokens/environment.token';
import { DataTypeUtils } from '@sulser-print/utils/data-type.utils';

import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class UserPermissionsStore {
	private readonly _permissions = signal<Array<UserPermission>>([]);

	private readonly environment = getEnvironmentConfig();

	private readonly key = 'permissions';

	private readonly storageService = inject(StorageService);

	readonly permissions = this._permissions.asReadonly();

	constructor() {
		effect(() => {
			const permissions = this.permissions();
			const storageKey = [this.key, '-', this.environment.currentEnvironment].join('');
			if (!permissions || permissions.length === 0) {
				this.storageService.remove(storageKey);
			} else {
				this.storageService.set(storageKey, JSON.stringify(this.permissions()));
			}
		});
		this._permissions.set(this.getPermissionsFromStorage());
	}

	private getPermission(module: string, action: string) {
		return this.permissions().find((permission) => permission.module === module && permission.action === action);
	}

	private getPermissionsFromStorage() {
		const permissions = this.storageService.get([this.key, '-', this.environment.currentEnvironment].join(''));
		return permissions ? JSON.parse(permissions) : [];
	}

	arePermissionsAvailable() {
		return this.permissions().length > 0;
	}

	hasAccess(module: string, action: string) {
		const permission = this.getPermission(module, action);
		return !DataTypeUtils.isNullOrUndefined(permission) && permission.access;
	}

	hasPermission(permission: string) {
		if (!permission) {
			return false;
		}

		return this.permissions().some((p) => p.access && `${p.module}.${p.action}`.toLowerCase().includes(permission));
	}

	removePermissions(): this {
		this._permissions.set([]);

		return this;
	}

	setPermissions(permissions: Array<UserPermission>): this {
		this._permissions.set(permissions);

		return this;
	}
}
